<template>
  <div>
    <notifications group="notification" position="top right" />

    <div class="row">
      <div class="col-md-12">
        <div class="card card-custom gutter-b">
          <div class="card-body">
            <div class="form-group mb-0">
              <vSelect
                v-model="selectedDevice"
                @input="onDeviceSelect"
                class="form-control form-control-solid form-control--device-select"
                placeholder="Select device"
                :options="devices"
                :reduce="(device) => device['@id']"
                label="name"
              >
                <template #option="{ name, location, tags }">
                  <span class="mr-3">{{ name }}</span>
                  <span class="badge badge-primary mr-3">{{
                    location.title
                  }}</span>
                  <span v-if="tags[0]" class="badge badge-secondary mr-3">{{
                    tags[0].name
                  }}</span>
                  <span v-if="location.lessor" class="badge badge-secondary">{{
                    location.lessor.name
                  }}</span>
                </template>
              </vSelect>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isMaintenancesLoaded" class="row">
      <div class="col-md-12">
        <div class="card card-custom gutter-b">
          <div class="card-header border-0 pt-5 pb-0">
            <div class="card-title"></div>
            <div class="card-toolbar">
              <button
                @click="showAllComments"
                class="btn btn-light-primary font-weight-bold px-5 mr-3"
              >
                {{ commentButtonText }}
              </button>
              <button
                @click.prevent="exportAsExcel"
                class="btn btn-light-primary font-weight-bold px-10"
              >
                Export .xlsx
              </button>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                <div v-if="!isDeviceMaintenancesEmpty" class="table-responsive">
                  <table
                    class="table table-head-custom table-head-bg table-vertical-center"
                  >
                    <thead>
                      <tr class="bg-gray-100 text-left">
                        <th class="pl-6">{{ $t('GENERAL.DEVICE') }}</th>
                        <th>{{ $t('GENERAL.LOCATION') }}</th>
                        <th>
                          <span
                            @click="orderByIsMoneyRaised"
                            class="cursor-pointer"
                          >
                            <span
                              class="pt-2"
                              :class="{
                                'text-primary': isMoneyRaisedOrder.isActive,
                              }"
                            >
                              Money Raised</span
                            >
                            <span
                              class="svg-icon svg-icon-sm pl-2"
                              :class="{
                                'svg-icon-primary': isMoneyRaisedOrder.isActive,
                              }"
                            >
                              <inline-svg
                                v-if="isMoneyRaisedOrder.query === 'asc'"
                                src="media/svg/icons/Navigation/Down-2.svg"
                              />
                              <inline-svg
                                v-else
                                src="media/svg/icons/Navigation/Up-2.svg"
                              />
                            </span>
                          </span>
                        </th>
                        <th>
                          <span
                            @click="orderByIsLabelChecked"
                            class="cursor-pointer"
                          >
                            <span
                              class="pt-2"
                              :class="{
                                'text-primary': isLabelCheckedOrder.isActive,
                              }"
                            >
                              Label Checked</span
                            >
                            <span
                              class="svg-icon svg-icon-sm pl-2"
                              :class="{
                                'svg-icon-primary':
                                  isLabelCheckedOrder.isActive,
                              }"
                            >
                              <inline-svg
                                v-if="isLabelCheckedOrder.query === 'asc'"
                                src="media/svg/icons/Navigation/Down-2.svg"
                              />
                              <inline-svg
                                v-else
                                src="media/svg/icons/Navigation/Up-2.svg"
                              />
                            </span>
                          </span>
                        </th>
                        <th>
                          <span
                            @click="orderByIsDeviceTested"
                            class="cursor-pointer"
                          >
                            <span
                              class="pt-2"
                              :class="{
                                'text-primary': isDeviceTestedOrder.isActive,
                              }"
                            >
                              Device Tested</span
                            >
                            <span
                              class="svg-icon svg-icon-sm pl-2"
                              :class="{
                                'svg-icon-primary':
                                  isDeviceTestedOrder.isActive,
                              }"
                            >
                              <inline-svg
                                v-if="isDeviceTestedOrder.query === 'asc'"
                                src="media/svg/icons/Navigation/Down-2.svg"
                              />
                              <inline-svg
                                v-else
                                src="media/svg/icons/Navigation/Up-2.svg"
                              />
                            </span>
                          </span>
                        </th>
                        <th>
                          <span
                            @click="orderByIsDeviceReset"
                            class="cursor-pointer"
                          >
                            <span
                              class="pt-2"
                              :class="{
                                'text-primary': isDeviceResetOrder.isActive,
                              }"
                            >
                              Device Reset</span
                            >
                            <span
                              class="svg-icon svg-icon-sm pl-2"
                              :class="{
                                'svg-icon-primary': isDeviceResetOrder.isActive,
                              }"
                            >
                              <inline-svg
                                v-if="isDeviceResetOrder.query === 'asc'"
                                src="media/svg/icons/Navigation/Down-2.svg"
                              />
                              <inline-svg
                                v-else
                                src="media/svg/icons/Navigation/Up-2.svg"
                              />
                            </span>
                          </span>
                        </th>
                        <th>
                          <span
                            @click="orderByIsCoinAcceptorCleaned"
                            class="cursor-pointer"
                          >
                            <span
                              class="pt-2"
                              :class="{
                                'text-primary':
                                  isCoinAcceptorCleanedOrder.isActive,
                              }"
                            >
                              Coin Acceptor Cleaned</span
                            >
                            <span
                              class="svg-icon svg-icon-sm pl-2"
                              :class="{
                                'svg-icon-primary':
                                  isCoinAcceptorCleanedOrder.isActive,
                              }"
                            >
                              <inline-svg
                                v-if="
                                  isCoinAcceptorCleanedOrder.query === 'asc'
                                "
                                src="media/svg/icons/Navigation/Down-2.svg"
                              />
                              <inline-svg
                                v-else
                                src="media/svg/icons/Navigation/Up-2.svg"
                              />
                            </span>
                          </span>
                        </th>
                        <th>Comment</th>
                        <th>
                          <span
                            @click="orderByCreatedAt"
                            class="cursor-pointer"
                          >
                            <span
                              class="pt-2"
                              :class="{
                                'text-primary': createdAtOrder.isActive,
                              }"
                            >
                              Date</span
                            >
                            <span
                              class="svg-icon svg-icon-sm pl-2"
                              :class="{
                                'svg-icon-primary': createdAtOrder.isActive,
                              }"
                            >
                              <inline-svg
                                v-if="createdAtOrder.query === 'asc'"
                                src="media/svg/icons/Navigation/Down-2.svg"
                              />
                              <inline-svg
                                v-else
                                src="media/svg/icons/Navigation/Up-2.svg"
                              />
                            </span>
                          </span>
                        </th>
                        <th class="text-right pr-6">User</th>
                      </tr>
                    </thead>
                    <tbody
                      v-for="(maintenance, key) in deviceMaintenances"
                      :key="key"
                    >
                      <tr>
                        <td :class="{ 'border-top-0': key === 0 }" class="pl-6">
                          <span
                            class="text-dark-75 font-weight-bolder mb-1 font-size-lg"
                            >{{ maintenance.device.name }}</span
                          >
                          <div>
                            <span
                              class="label label-sm label-light-primary label-inline mr-2"
                              >{{ maintenance.device.tags[0].name }}</span
                            >
                          </div>
                        </td>
                        <td :class="{ 'border-top-0': key === 0 }">
                          <span>{{ maintenance.device.location.title }}</span>
                        </td>
                        <td :class="{ 'border-top-0': key === 0 }">
                          <span
                            v-if="maintenance.data.isMoneyRaised"
                            class="label label-lg label-light-success label-inline"
                        >{{ $t('GENERAL.YES') }}
                            </span
                          >
                          <span
                            v-else
                            class="label label-lg label-light-danger label-inline"
                            >{{ $t('GENERAL.NO') }}</span
                          >
                        </td>
                        <td :class="{ 'border-top-0': key === 0 }">
                          <span
                            v-if="maintenance.data.isLabelChecked"
                            class="label label-lg label-light-success label-inline"
                            >{{ $t('GENERAL.YES') }}</span
                          >
                          <span
                            v-else
                            class="label label-lg label-light-danger label-inline"
                            >{{ $t('GENERAL.NO') }}</span
                          >
                        </td>
                        <td :class="{ 'border-top-0': key === 0 }">
                          <span
                            v-if="maintenance.data.isDeviceTested"
                            class="label label-lg label-light-success label-inline"
                            >{{ $t('GENERAL.YES') }}</span
                          >
                          <span
                            v-else
                            class="label label-lg label-light-danger label-inline"
                            >{{ $t('GENERAL.NO') }}</span
                          >
                        </td>
                        <td :class="{ 'border-top-0': key === 0 }">
                          <span
                            v-if="maintenance.data.isDeviceReset"
                            class="label label-lg label-light-success label-inline"
                            >{{ $t('GENERAL.YES') }}</span
                          >
                          <span
                            v-else
                            class="label label-lg label-light-danger label-inline"
                            >{{ $t('GENERAL.NO') }}</span
                          >
                        </td>
                        <td :class="{ 'border-top-0': key === 0 }">
                          <span
                            v-if="maintenance.data.isCoinAcceptorCleaned"
                            class="label label-lg label-light-success label-inline"
                            >{{ $t('GENERAL.YES') }}</span
                          >
                          <span
                            v-else
                            class="label label-lg label-light-danger label-inline"
                            >{{ $t('GENERAL.NO') }}</span
                          >
                        </td>
                        <td :class="{ 'border-top-0': key === 0 }">
                          <button
                            @click.prevent="
                              toggleDeviceServiceModeHistoryCommentModal(
                                maintenance.data.comment
                              )
                            "
                            class="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon mr-2"
                          >
                            <span class="svg-icon svg-icon-md">
                              <inline-svg
                                src="media/svg/icons/Clothes/Sun-glasses.svg"
                              />
                            </span>
                          </button>
                        </td>
                        <td :class="{ 'border-top-0': key === 0 }">
                          <span>{{
                            maintenance.createdAt | formatDateTime
                          }}</span>
                        </td>
                        <td
                          :class="{ 'border-top-0': key === 0 }"
                          class="text-right pr-6"
                        >
                          <span>{{ maintenance.author }}</span>
                        </td>
                      </tr>
                      <tr v-if="isAllCommentsVisible">
                        <td class="px-6 border-top-0" colspan="9">
                          <span class="font-weight-bold">Full comment:</span>
                          <span> {{ maintenance.data.comment }}</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div v-else>
                  <div class="alert alert-primary mb-0" role="alert">
                    This Device have no Maintenance History
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <b-modal
          id="device_service_mode_history_comment"
          size="md"
          centered
          hide-header
          hide-footer
        >
          <div v-if="isCommentVisible">
            <b-button
              class="btn btn-light-primary mb-3"
              block
              @click="$bvModal.hide('device_service_mode_history_comment')"
              >Close</b-button
            >
            <div>
              {{ currentEntityComment }}
            </div>
          </div>
        </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import ApiService from '@/core/services/api.service'
import { mapGetters } from 'vuex'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import axios from 'axios'

export default {
  name: 'ByDeviceMaintenance',
  components: {
    vSelect,
  },
  computed: {
    ...mapGetters(['currentUserRole', 'currentUserCompany']),
  },
  data() {
    return {
      devices: [],
      selectedDevice: null,
      deviceMaintenances: [],
      isMaintenancesLoaded: false,
      isDeviceMaintenancesEmpty: false,
      currentEntityComment: null,
      isCommentVisible: false,
      isAllCommentsVisible: false,
      commentButtonText: 'Show All Comments',
      isMoneyRaisedOrder: {
        query: 'asc',
        isActive: false,
      },
      isLabelCheckedOrder: {
        query: 'asc',
        isActive: false,
      },
      isDeviceTestedOrder: {
        query: 'asc',
        isActive: false,
      },
      isDeviceResetOrder: {
        query: 'asc',
        isActive: false,
      },
      isCoinAcceptorCleanedOrder: {
        query: 'asc',
        isActive: false,
      },
      createdAtOrder: {
        query: 'asc',
        isActive: false,
      },
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Device Maintenance by Devices', route: '' },
    ])

    if (
      this.currentUserRole === 'ROLE_ROOT' ||
      this.currentUserRole === 'ROLE_SUPER_ADMIN'
    ) {
      this.getDevices('devices', '?pagination=false').then(({ data }) => {
        this.devices = [...data['hydra:member']]
      })
    }

    if (
      this.currentUserRole === 'ROLE_ADMIN' ||
      this.currentUserRole === 'ROLE_WORKER' ||
      this.currentUserRole === 'ROLE_JUNIOR'
    ) {
      ApiService.get(
        'devices',
        `?company=${this.currentUserCompany.replace(
          '/api/v1/companies/',
          ''
        )}&pagination=false`
      ).then(({ data }) => {
        this.devices = [...data['hydra:member']]
      })
    }
  },
  methods: {
    onDeviceSelect() {
      ApiService.get(
        'device_maintenances',
        `?device=${this.selectedDevice}`
      ).then(({ data }) => {
        this.deviceMaintenances = [...data['hydra:member']]
        this.isMaintenancesLoaded = true
        this.isDeviceMaintenancesEmpty = this.deviceMaintenances.length === 0
      })
    },
    async getDevices(resource) {
      try {
        return await ApiService.get(resource)
      } catch (e) {
        throw new Error(e)
      }
    },
    toggleDeviceServiceModeHistoryCommentModal(comment) {
      this.isCommentVisible = false
      this.currentEntityComment = comment
      this.isCommentVisible = true

      this.$root.$emit('bv::show::modal', 'device_service_mode_history_comment')
    },
    showAllComments() {
      if (this.isAllCommentsVisible) {
        this.isAllCommentsVisible = false
        this.commentButtonText = 'Show All Comments'
      } else {
        this.isAllCommentsVisible = true
        this.commentButtonText = 'Hide All Comments'
      }
    },
    orderByIsMoneyRaised() {
      this.isMoneyRaisedOrder.isActive = true

      if (this.isMoneyRaisedOrder.query === 'asc') {
        this.isMoneyRaisedOrder.query = 'desc'
        this.deviceMaintenances.sort(
          (a, b) => a.data.isMoneyRaised - b.data.isMoneyRaised
        )
      } else {
        this.isMoneyRaisedOrder.query = 'asc'
        this.deviceMaintenances.sort(
          (a, b) => b.data.isMoneyRaised - a.data.isMoneyRaised
        )
      }
    },
    orderByIsLabelChecked() {
      this.isLabelCheckedOrder.isActive = true

      if (this.isLabelCheckedOrder.query === 'asc') {
        this.isLabelCheckedOrder.query = 'desc'
        this.deviceMaintenances.sort(
          (a, b) => a.data.isLabelChecked - b.data.isLabelChecked
        )
      } else {
        this.isLabelCheckedOrder.query = 'asc'
        this.deviceMaintenances.sort(
          (a, b) => b.data.isLabelChecked - a.data.isLabelChecked
        )
      }
    },
    orderByIsDeviceTested() {
      this.isDeviceTestedOrder.isActive = true

      if (this.isDeviceTestedOrder.query === 'asc') {
        this.isDeviceTestedOrder.query = 'desc'
        this.deviceMaintenances.sort(
          (a, b) => a.data.isDeviceTested - b.data.isDeviceTested
        )
      } else {
        this.isDeviceTestedOrder.query = 'asc'
        this.deviceMaintenances.sort(
          (a, b) => b.data.isDeviceTested - a.data.isDeviceTested
        )
      }
    },
    orderByIsDeviceReset() {
      this.isDeviceResetOrder.isActive = true

      if (this.isDeviceResetOrder.query === 'asc') {
        this.isDeviceResetOrder.query = 'desc'
        this.deviceMaintenances.sort(
          (a, b) => a.data.isDeviceReset - b.data.isDeviceReset
        )
      } else {
        this.isDeviceResetOrder.query = 'asc'
        this.deviceMaintenances.sort(
          (a, b) => b.data.isDeviceReset - a.data.isDeviceReset
        )
      }
    },
    orderByIsCoinAcceptorCleaned() {
      this.isCoinAcceptorCleanedOrder.isActive = true

      if (this.isCoinAcceptorCleanedOrder.query === 'asc') {
        this.isCoinAcceptorCleanedOrder.query = 'desc'
        this.deviceMaintenances.sort(
          (a, b) => a.data.isCoinAcceptorCleaned - b.data.isCoinAcceptorCleaned
        )
      } else {
        this.isCoinAcceptorCleanedOrder.query = 'asc'
        this.deviceMaintenances.sort(
          (a, b) => b.data.isCoinAcceptorCleaned - a.data.isCoinAcceptorCleaned
        )
      }
    },
    orderByCreatedAt() {
      this.createdAtOrder.isActive = true

      if (this.createdAtOrder.query === 'asc') {
        this.createdAtOrder.query = 'desc'
        this.deviceMaintenances.sort((a, b) => a.createdAt - b.createdAt)
      } else {
        this.createdAtOrder.query = 'asc'
        this.deviceMaintenances.sort((a, b) => b.createdAt - a.createdAt)
      }
    },
    exportAsExcel() {
      axios
        .get(
          `${process.env.VUE_APP_ROUTE_API_URL}/api/v1/device_maintenances/export/by-device/` +
            this.selectedDevice.replace('/api/v1/devices/', '')
        )
        .then(({ data }) => {
          if (data.status === 'ok') {
            window.location.replace(data.fileUrl)
            this.$notify({
              group: 'notification',
              type: 'success',
              title: 'Export success',
            })
          }
        })
    },
  },
}
</script>

<style lang="scss">
@import 'vue-select/src/scss/vue-select.scss';

.form-control {
  &--device-select {
    height: unset !important;

    .vs__dropdown-toggle {
      padding: 0;
      border: 0;
      border-radius: 0;
    }
    .vs__dropdown-menu {
      border: 1px solid #e4e6ef;
      -webkit-box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
      box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);

      /* width */
      &::-webkit-scrollbar {
        width: 4px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #888;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }

      li {
        padding: 0.65rem 1rem;

        &:hover {
          background: #f3f6f9;
          color: #3f4254;
        }
      }
    }

    .vs__open-indicator {
    }
    .vs__dropdown-toggle {
    }
    .vs__clear {
      display: none;
    }
    .vs__selected {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      padding: 0.25rem 0.75rem;
      font-size: 1rem;
      margin: 0.1rem 0.4rem 0.1rem 0;
      position: relative;
      float: left;
      color: #3f4254;
      background: #f3f6f9;
      border: 0;
      border-radius: 0.42rem;
    }
    .vs__no-options {
    }
    .vs__spinner {
    }
    .vs__deselect {
    }
    .vs__active {
    }
    .vs__dropdown-option--highlight {
      background: #ebedf3;
      color: #3f4254;
    }
  }
}
</style>
